import * as React from "react";

interface LogeniosErrorBoundaryState {
    hasError: boolean
    hasDOMError: boolean
}

class LogeniosErrorBoundary extends React.Component<any, LogeniosErrorBoundaryState> {
    constructor(props: any) {
        super(props);
        this.state = {
            hasError: false,
            hasDOMError: false
        };
    }

    componentDidCatch(error: any, info: any) {
        // Display fallback UI
        // console.log("Error info: " + info)
        // console.log("Error : " + error)
        if( error == "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.") {
            this.setState({ hasDOMError: true, hasError: true });
        }

        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        console.error("Error occurred: " + error)
    }

    static getDerivedStateFromError(error: any) {
        // console.log("Error derivedState: " + error)
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    render() {
        if (this.state.hasDOMError) {
            return <div>
                <img src="/assets/icons/logo-with-text-mid.png" alt={"Logenios"}/>

                <br />
                <b>Achtung: </b>
                Beim Darstellen der Logenios-Website ist ein Fehler aufgetreten. Möglicherweise hängt der Fehler mit einem Browser-Plugin zusammen. Bitte deaktivieren Sie testweise Ihre Browser Plugins.
                <br/>
                Sollte dies nicht helfen, kontaktieren Sie bitte den Logenios Support.

                <br /><br />
                <b>Warning: </b>
                The rendering of the logenios web page failed. Maybe the error was caused by a misbehaving browser plugin. Please disable your browser plugins temporarily.
                <br/>
                Should this not fix the problem, please contact the Logenios support.</div>
        }
        else if(this.state.hasError){
            // You can render any custom fallback UI
            return <div>
                <img src="/assets/icons/logo-with-text-mid.png" alt={"Logenios"}/>

                <br />
                <b>Achtung: </b>
                Da ist etwas schiefgelaufen. Es scheint ein Problem mit der Web-Applikation zu geben. Gehen Sie im Browser zurück, laden Sie die Seite erneut oder versuchen Sie es später noch einmal.
                <br/>
                Falls der Fehler häufiger Auftritt, bitte melden Sie sich bei Ihrem Logenios Ansprechpartner.

                <br /><br />
                <b>Warning: </b>
                Something went wrong. There seems to be a problem with the application. Use the browser back functionality, reload or try again later.
                <br/>
                If the issue persists, please contact your Logenios contact person.</div>
        }

        return this.props.children;
    }
}

export default LogeniosErrorBoundary