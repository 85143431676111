import * as React from "react";
import {ChangeEvent, useEffect, useState} from "react";
import {addRule, getParseOptions, getSaveToConfig, removeRule, selectRules, setSaveToConfig} from "../slices/ruleSlice";
import {useDispatch, useSelector} from "react-redux";
import "./RuleEditForm.css"
import {
    getRuleToDisplay,
    isEditSelectedRuleModeActive,
    setEditSelectedRuleMode,
    setRuleToDisplay
} from "../slices/rightMenuSlice";
import {tagList} from "../misc/tag_model";
import {Button, Popup, TextArea} from "semantic-ui-react";
import {DropdownProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
import {TextAreaProps} from "semantic-ui-react/dist/commonjs/addons/TextArea/TextArea";
import {getOpenedFiles} from "../slices/tableSlice";
import {GenericXlsExtractionRule} from "../../../../../generated";
import MatchWhenEnum = GenericXlsExtractionRule.MatchWhenEnum;
import SelectorEnum = GenericXlsExtractionRule.SelectorEnum;
import SelectorMatchWhenEnum = GenericXlsExtractionRule.SelectorMatchWhenEnum;
import ActionEnum = GenericXlsExtractionRule.ActionEnum;
import SplitPointSelectionEnum = GenericXlsExtractionRule.SplitPointSelectionEnum;
import IfNotAvailableTriggerEnum = GenericXlsExtractionRule.IfNotAvailableTriggerEnum;

interface RuleEditProps {
    rules: GenericXlsExtractionRule[]
}

export function RuleEditForm(props: React.PropsWithChildren<RuleEditProps>) {
    const editSelectedRuleModeActive = useSelector(isEditSelectedRuleModeActive)
    const ruleToDisplay = useSelector(getRuleToDisplay)
    const openedFiles = useSelector(getOpenedFiles)
    const autoSaveConfig = useSelector(getSaveToConfig)

    function toggleEditSelection() {
        dispatch(setEditSelectedRuleMode(!editSelectedRuleModeActive))
    }

    function onSaveBtn() {
        props.rules.forEach(rule => {

            const newRule: GenericXlsExtractionRule = {...rule, ...formState}

            if (newRule.parentRuleId == -1)
                newRule.parentRuleId = undefined

            dispatch(addRule(newRule))
            dispatch(setRuleToDisplay(newRule))
            dispatch(setSaveToConfig(!autoSaveConfig))
        })
    }

    function deriveState(): GenericXlsExtractionRule {
        let initialState: GenericXlsExtractionRule;
        const firstRule = props.rules[0];

        if (props.rules.length == 1) {

            initialState = {
                ...firstRule,
                matchText: firstRule.matchText ?? "",
                tagName: firstRule.tagName ?? "",
                selectionLabel: firstRule.selectionLabel ?? "",
                selectorMatchText: firstRule.selectorMatchText ?? "",
                matchWhen: firstRule.matchWhen ?? MatchWhenEnum.EXACTMATCH,
                matchOnColumns: firstRule.matchOnColumns ?? "",
                selector: firstRule.selector ?? SelectorEnum.RIGHTCELL,
                skipEmptyCells: firstRule.skipEmptyCells ?? false,
                selectNumLines: firstRule.selectNumLines ?? 1,
                selectNumColumns: firstRule.selectNumColumns ?? 1,
                action: firstRule.action ?? ActionEnum.TAG,
                selectorMatchWhen: firstRule.selectorMatchWhen ?? SelectorMatchWhenEnum.EXACTMATCH,
                parentRuleId: firstRule.parentRuleId ?? -1,
                skipNumEntriesAtStart: firstRule.skipNumEntriesAtStart ?? 0,
                skipNumEntriesAtEnd: firstRule.skipNumEntriesAtEnd ?? 0,
                splitEvery: firstRule.splitEvery ?? 0,
                extractionRegex: firstRule.extractionRegex ?? "",
                skipNumColumns: firstRule.skipNumColumns ?? 0,
                splitLineOffset: firstRule.splitLineOffset ?? 0,
                offsetLines: firstRule.offsetLines ?? 0,
                offsetColumns: firstRule.offsetColumns ?? 0,
                splitOnlyOnChange: firstRule.splitOnlyOnChange ?? false,
                comment: firstRule.comment ?? "",
                splitPointSelection: firstRule.splitPointSelection ?? SplitPointSelectionEnum.OFFSETBASED,
                ifNotAvailableTrigger: firstRule.ifNotAvailableTrigger ?? IfNotAvailableTriggerEnum.DONOTHING
            };
        }  else {
            initialState = {
                ...firstRule,
                matchWhen: firstRule.matchWhen ?? MatchWhenEnum.EXACTMATCH,
                matchOnColumns: firstRule.matchOnColumns ?? "",
                selector: firstRule.selector ?? SelectorEnum.RIGHTCELL,
                skipEmptyCells: firstRule.skipEmptyCells ?? false,
                selectNumLines: firstRule.selectNumLines ?? 1,
                selectNumColumns: firstRule.selectNumColumns ?? 1,
                action: firstRule.action ?? ActionEnum.TAG,
                selectorMatchWhen: firstRule.selectorMatchWhen ?? SelectorMatchWhenEnum.EXACTMATCH,
                parentRuleId: firstRule.parentRuleId ?? -1,
                skipNumEntriesAtStart: firstRule.skipNumEntriesAtStart ?? 0,
                skipNumEntriesAtEnd: firstRule.skipNumEntriesAtEnd ?? 0,
                splitEvery: firstRule.splitEvery ?? 0,
                extractionRegex: firstRule.extractionRegex ?? "",
                skipNumColumns: firstRule.skipNumColumns ?? 0,
                splitLineOffset: firstRule.splitLineOffset ?? 0,
                offsetLines: firstRule.offsetLines ?? 0,
                offsetColumns: firstRule.offsetColumns ?? 0,
                splitOnlyOnChange: firstRule.splitOnlyOnChange ?? false,
                comment: firstRule.comment ?? "",
                splitPointSelection: firstRule.splitPointSelection ?? SplitPointSelectionEnum.OFFSETBASED,
                ifNotAvailableTrigger: firstRule.ifNotAvailableTrigger ?? IfNotAvailableTriggerEnum.DONOTHING
            };
        }
        return initialState;
    }


    const excludeSelfAndChilds = (possibleParentRules: GenericXlsExtractionRule[]): GenericXlsExtractionRule[] => {
        const knownChilds = props.rules.map(r => r.ruleId)

        let hasChanged = true
        while (hasChanged) {
            hasChanged = false
            possibleParentRules.forEach(checkRule => {
                if (knownChilds.findIndex(y => y == checkRule.parentRuleId) != -1 && knownChilds.findIndex(y => y == checkRule.ruleId) == -1) {
                    knownChilds.push(checkRule.ruleId)
                    hasChanged = true
                }
            })
        }

        return possibleParentRules.filter(r => knownChilds.indexOf(r.ruleId) == -1)
    }

    const possibleParentRules = excludeSelfAndChilds(useSelector(selectRules))
    const [formState, setFormState] = useState(deriveState())

    const options = useSelector(getParseOptions)
    const dispatch = useDispatch()


    useEffect(() => {
        setFormState(deriveState())
    }, [props])

    const handleEvent = (evt: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        let valueToSet: any = evt.target.value
        if (evt.target.type === 'checkbox' && "checked" in evt.target)
            valueToSet = evt.target.checked
        if (evt.target.type === 'number')
            valueToSet = parseFloat(evt.target.value)
        setFormState({
            ...formState,
            [evt.target.name]: valueToSet
        })
    }
    const handleTextAreaEvent = (event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
        let valueToSet: any = data.value
        setFormState({
            ...formState,
            [event.target.name]: valueToSet
        })
    }


    const handleDropdownChange = (evt: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        let valueToSet: any = data.value.toString()
        console.log("dropdown value, ", valueToSet)
        setFormState({
            ...formState,
            [data.name]: valueToSet
        })
    }

    const extendedTagList = [...tagList, ...options?.aiPrompts?.map(p => p.name) ?? []]

    return <div id="RuleEditForm">
        {formState.hasOwnProperty("matchText") &&
            <details open>
                <summary>Match</summary>
                <fieldset className="formFlex">
                    <div>
                        <label>Match on</label>
                        <TextArea name={"matchText"}
                                  value={formState["matchText"]}
                                  onChange={handleTextAreaEvent}
                        />
                    </div>
                    <div>
                        <label>Match when</label>
                        <select onChange={handleEvent} name={"matchWhen"} value={formState.matchWhen}>
                            <option value={"EXACT_MATCH"}>Cell contains exactly</option>
                            <option value={"PARTIAL_MATCH"}>Cell contains substring</option>
                            <option value={"REGEX_MATCH"}>Cell matches Regex</option>
                            <option value={"MATCH_ON_LINE"}>Match line # in buffer</option>
                        </select>
                    </div>
                    <div>
                        <label>Match only on columns</label>
                        <input type={"text"} value={formState.matchOnColumns} name={"matchOnColumns"}
                               onChange={handleEvent}
                               placeholder={"range like 4-7 or column list like 1,2,3,"}/>
                    </div>
                </fieldset>
            </details>
        }
        <details open>
            <summary>Selection</summary>
            <fieldset className="formFlex">

                {/*<legend>Selection</legend>*/}
                <div>
                    <label>Select cell/range</label>
                    <select name={"selector"} onChange={handleEvent} value={formState.selector}>
                        <option value={"THIS_CELL"}>This Cell</option>
                        <option value={"RIGHT_CELL"}>Cell right</option>
                        <option value={"LEFT_CELL"}>Cell left</option>
                        <option value={"NEXT_CELL_DOWN"}>Cell below</option>
                        <option value={"NEXT_CELL_UP"}>Cell above</option>
                        <option value={"NEXT_ROW"}>Next Line</option>
                        <option value={"THIS_ROW"}>This Line</option>
                        <option value={"PREV_ROW"}>Previous Line</option>
                        <option value={"THIS_COLUMN"}>This Column</option>
                        <option value={"RANGE_WITH_SIZE"}>Range with Size</option>
                        <option value={"AREA_TILL_CELL"}>Exact Range till cell</option>
                        <option value={"ROWS_TILL_CELL"}>All cells till cell matching</option>
                        <option value={"ROWS_TILL_NEXT_MATCH"}>All rows till next appearance of</option>
                        <option value={"EXCLUDE_ROWS_TILL_CELL"}>Exclude all rows till next appearance of</option>
                        <option value={"SPLIT_ALONG_LINE_BEFORE"}>Split along line before matching cell</option>
                        <option value={"REMAINING_BUFFER"}>Select rest of buffer</option>
                        <option value={"REMAINING_BUFFER_SPLIT_EVERY"}>Select rest of buffer and split every n rows
                        </option>
                    </select>
                </div>
                {
                    ["THIS_COLUMN"].indexOf(formState.selector) > -1 &&
                    <>
                        <div>
                            <label>Num Lines to Skip at Start</label>
                            <input type="number" value={formState.skipNumEntriesAtStart}
                                   name={"skipNumEntriesAtStart"} onChange={handleEvent}/>
                        </div>
                        <div>

                            <label>Num Lines to Skip at End</label>
                            <input type="number" value={formState.skipNumEntriesAtEnd}
                                   name={"skipNumEntriesAtEnd"}
                                   onChange={handleEvent}/>
                        </div>
                    </>
                }
                {
                    ["SPLIT_ALONG_LINE_BEFORE", "SPLIT_ALONG_LINE_AFTER"].indexOf(formState.selector) > -1 &&
                    <>
                        <div>
                            <label>Num Selections to Skip at Start</label>
                            <input type="number" value={formState.skipNumEntriesAtStart}
                                   name={"skipNumEntriesAtStart"} onChange={handleEvent}/>

                        </div>
                        <div>
                            <label>Num Selections to Skip at End</label>
                            <input type="number" value={formState.skipNumEntriesAtEnd}
                                   name={"skipNumEntriesAtEnd"}
                                   onChange={handleEvent}/>

                        </div>
                        <div>

                            <label>Split point</label>
                            <select name={"splitPointSelection"} onChange={handleEvent}
                                    value={formState.splitPointSelection}>
                                <option value={"OFFSET_BASED"}>Static offset</option>
                                <option value={"MATCHING_CELL_BASED"}>On Cell matching</option>
                            </select>
                        </div>


                        {(!formState.splitPointSelection || formState.splitPointSelection == "OFFSET_BASED") &&
                            <div>
                                <label>Split line offset</label>
                                <input type="number" value={formState.splitLineOffset} name={"splitLineOffset"}
                                       onChange={handleEvent}/>
                            </div>
                        }
                        {(formState.splitPointSelection == "MATCHING_CELL_BASED") &&
                            <>
                                <div>
                                    <label>Split where cell matches</label>
                                    <input type="text" value={formState["selectorMatchText"]}
                                           name={"selectorMatchText"} onChange={handleEvent}/>
                                </div>
                                <div>

                                    <label>Matching criterion</label>
                                    <select onChange={handleEvent} name={"selectorMatchWhen"}
                                            value={formState.selectorMatchWhen}>
                                        <option value={"EXACT_MATCH"}>Cell contains exactly</option>
                                        <option value={"PARTIAL_MATCH"}>Cell contains substring</option>
                                        <option value={"REGEX_MATCH"}>Cell matches Regex</option>
                                    </select>
                                </div>
                            </>
                        }
                        <div>
                            <label>Split only on value change</label>
                            <input type="checkbox" checked={formState.splitOnlyOnChange}
                                   name={"splitOnlyOnChange"}
                                   onChange={handleEvent}/>
                        </div>
                        {
                            formState.splitOnlyOnChange &&
                            <>
                                <div>
                                    <label>Change Value Line Offset</label>
                                    <input type="number" value={formState.offsetLines} name={"offsetLines"}
                                           onChange={handleEvent}/>
                                </div>
                                <div>
                                    <label>Change Value Column Offset</label>
                                    <input type="number" value={formState.offsetColumns} name={"offsetColumns"}
                                           onChange={handleEvent}/>
                                </div>
                                <div>
                                    <label>Skip empty cells</label>
                                    <input type="checkbox" checked={formState.skipEmptyCells}
                                           name={"skipEmptyCells"} onChange={handleEvent}/>
                                </div>
                            </>
                        }
                    </>
                }
                {
                    ["AREA_TILL_CELL", "ROWS_TILL_CELL", "EXCLUDE_ROWS_TILL_CELL"].indexOf(formState.selector) > -1 &&
                    <>
                        {
                            formState.hasOwnProperty("selectorMatchText") &&
                            <div>
                                <label>Range till cell</label>
                                <input type="text" value={formState["selectorMatchText"]}
                                       name={"selectorMatchText"} onChange={handleEvent}/>
                            </div>
                        }

                        <div>
                            <label>Match if</label>
                            <select onChange={handleEvent} name={"selectorMatchWhen"}
                                    value={formState.selectorMatchWhen}>
                                <option value={"EXACT_MATCH"}>Cell contains exactly</option>
                                <option value={"PARTIAL_MATCH"}>Cell contains substring</option>
                                <option value={"REGEX_MATCH"}>Cell matches Regex</option>
                            </select>
                        </div>

                        <div>
                            <label>Num Lines to Skip at Start</label>
                            <input type="number" value={formState.skipNumEntriesAtStart}
                                   name={"skipNumEntriesAtStart"} onChange={handleEvent}/>

                        </div>
                        <div>
                            <label>Num Lines to Skip at End</label>
                            <input type="number" value={formState.skipNumEntriesAtEnd}
                                   name={"skipNumEntriesAtEnd"}
                                   onChange={handleEvent}/>
                        </div>
                    </>
                }
                {
                    ["RIGHT_CELL", "LEFT_CELL", "NEXT_CELL_DOWN", "NEXT_CELL_UP"].indexOf(formState.selector) != -1 &&
                    <div>
                        <label>Skip empty cells</label>
                        <input name={"skipEmptyCells"} type="checkbox" onChange={handleEvent}
                               checked={formState.skipEmptyCells}/>
                    </div>
                }
                {
                    ["RANGE_WITH_SIZE"].indexOf(formState.selector) != -1 &&
                    <>
                        <div>
                            <label>Take # rows</label>
                            <input type="number" value={formState.selectNumLines} name={"selectNumLines"}
                                   onChange={handleEvent}/>
                        </div>
                        <div>

                            <label>Take # columns</label>
                            <input type="number" value={formState.selectNumColumns} name={"selectNumColumns"}
                                   onChange={handleEvent}/>

                        </div>
                        <div>
                            <label>Num rows to Skip at Start</label>
                            <input type="number" value={formState.skipNumEntriesAtStart}
                                   name={"skipNumEntriesAtStart"} onChange={handleEvent}/>

                        </div>
                        <div>
                            <label>Num Columns to Skip at Start</label>
                            <input type="number" value={formState.skipNumColumns} name={"skipNumColumns"}
                                   onChange={handleEvent}/>
                        </div>
                    </>
                }
                {
                    ["REMAINING_BUFFER_SPLIT_EVERY"].indexOf(formState.selector) != -1 &&
                    <>
                        <div>
                            <label>Num Lines to Skip at Start</label>
                            <input type="number" value={formState.skipNumEntriesAtStart}
                                   name={"skipNumEntriesAtStart"} onChange={handleEvent}/>

                        </div>
                        <div>
                            <label>Num Lines to Skip at End</label>
                            <input type="number" value={formState.skipNumEntriesAtEnd}
                                   name={"skipNumEntriesAtEnd"}
                                   onChange={handleEvent}/>
                        </div>
                        <div>

                            <label>Split every</label>
                            <input type="number" value={formState.splitEvery} name={"splitEvery"}
                                   onChange={handleEvent}/>
                        </div>
                    </>
                }
            </fieldset>
        </details>
        <details open>
            <summary>Action</summary>
            <fieldset className="formFlex">
                <div>
                    <label>Apply Action</label>
                    <select value={formState.action} name={"action"} onChange={handleEvent}>
                        <option value={"TAG"}>Slice Selection of Rule and apply tag</option>
                        <option value={"LABEL"}>Apply label to selection</option>
                        <option value={"SQUASH"}>Squash selection</option>
                        <option value={"REMOVE_EMPTY_ROWS_AND_COLUMNS"}>Remove empty lines and columns</option>
                        <option value={"REMOVE_EMPTY_ROWS_ONLY"}>Remove empty lines only</option>
                        <option value={"REMOVE_EMPTY_COLUMNS_ONLY"}>Remove empty columns only</option>
                    </select>
                </div>
                {
                    formState.action == "TAG" && formState.hasOwnProperty("tagName") &&
                    <div>
                        <label>Tag</label>
                        {/*<Dropdown fluid multiple selection*/}
                        {/*          search*/}
                        {/*          allowAdditions*/}
                        {/*          style={{maxWidth: "325px"}}*/}
                        {/*          options={extendedTagList.map((item) => ({*/}
                        {/*              key: item,*/}
                        {/*              text: item,*/}
                        {/*              value: item*/}
                        {/*          }))}*/}
                        {/*          value={formState["tagName"].split(',')}*/}
                        {/*    // value={}*/}
                        {/*          name={"tagName"}*/}
                        {/*          onChange={handleDropdownChange}*/}
                        {/*/>*/}
                        <input type="text" list="tag-options" value={formState["tagName"]} name={"tagName"}
                               onChange={handleEvent}/>

                        <datalist id="tag-options">
                            {extendedTagList.map((item) =>
                                <option key={item} value={item}/>
                            )}
                        </datalist>
                    </div>
                }
                {
                    formState.action == "LABEL" && formState.hasOwnProperty("selectionLabel") &&
                    <div>
                        <label>Selection Label</label>
                        {/*<Dropdown fluid multiple selection*/}
                        {/*          style={{maxWidth: "325px"}}*/}
                        {/*          options={extendedTagList.map((item) => ({*/}
                        {/*              key: item,*/}
                        {/*              text: item,*/}
                        {/*              value: item*/}
                        {/*          }))}*/}
                        {/*          defaultValue={formState["selectionLabel"]}*/}
                        {/*          name={"selectionLabel"}*/}
                        {/*          onChange={handleDropdownChange}*/}
                        {/*/>*/}
                        <input type="text" list="tag-options" value={formState["selectionLabel"]} name={"selectionLabel"}
                               onChange={handleEvent}/>
                        <datalist id="tag-options">
                            {extendedTagList.map((item) =>
                                <option key={item} value={item}/>
                            )}
                        </datalist>
                    </div>
                }
                <>
                    <div>
                        <label>Value Extraction Regex</label>
                        <TextArea name={"extractionRegex"}
                                  value={formState.extractionRegex}
                                  onChange={handleTextAreaEvent}
                        />
                    </div>
                    <div>
                        <label>Select action, if not found.</label>
                        <select onChange={handleEvent} name={"ifNotAvailableTrigger"}
                                value={formState.ifNotAvailableTrigger}>
                            <option value={"DO_NOTHING"} selected>Do nothing</option>
                            <option value={"SHOW_STATUS_VALIDATION_WARNING"}>Show status validation warning</option>
                            <option value={"GO_TO_INPUT_MISSING"}>Go to input missing</option>
                        </select>
                    </div>
                </>
            </fieldset>
        </details>

        <details>
            <summary>Parent</summary>
            <fieldset className="formFlex">

                <div>
                    <label>Select parent</label>
                    <select value={formState.parentRuleId} name={"parentRuleId"} onChange={handleEvent}>
                        <option key={-1} value={-1}>Parent</option>
                        {
                            possibleParentRules
                                .map(parentRule => <option key={parentRule.ruleId}
                                                           value={parentRule.ruleId}>{parentRule.ruleId} - {parentRule.matchText}</option>)
                        }
                    </select>
                </div>
            </fieldset>
        </details>

        <details>
            <summary>Additional Instructions</summary>
            <fieldset className="formFlex">
                <div>
                    <label>Comment</label>
                    <TextArea name={"comment"}
                              value={formState.comment}
                              onChange={handleTextAreaEvent}
                    />
                </div>
            </fieldset>
        </details>


        <div className={"flexChildrenEqualWidth"}>
            <Popup content='Edit Selection' trigger={
                <Button content={"View Selection"} primary={editSelectedRuleModeActive && ruleToDisplay != null}
                        onClick={toggleEditSelection}
                        disabled={openedFiles?.length < 1 && ruleToDisplay == null}/>
            }/>
            <Button id={"ruleSaveBtn"} primary onClick={onSaveBtn}>Save</Button>
            <Button id={"ruleRemoveBtn"} negative
                    onClick={() => {
                        props.rules.forEach(r => dispatch(removeRule(r)));
                        dispatch(setSaveToConfig(!autoSaveConfig));
                    }}>
            Remove
            </Button>
        </div>
    </div>
}

