import * as React from "react";
import {useEffect, useState} from "react";
import {Button, Checkbox, Icon} from "semantic-ui-react";
import ReactTable from "react-table";
import {formatDateFromStringNoTime} from "../../../../../format";
import {BillingRequest, BillWorkLogBookEntriesRequest, ProjectWorkLogBookEntry} from "../../../../../generated";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {notify} from 'react-notify-toast';

export interface LogEntriesTableProps {
    selected: ProjectWorkLogBookEntry[],
    setSelected: (s: ProjectWorkLogBookEntry[]) => void
    year: number
    setYear: (y: number) => void
    month: number
    setMonth: (m: number) => void
}

export function LogEntriesTable(
    {
        selected, 
        setSelected, 
        year,
        setYear,
        month,
        setMonth
    }: React.PropsWithChildren<LogEntriesTableProps>) {

    const [isLoading, setIsLoading] = useState(true)
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [take, setTake] = useState(25);
    const [sortBy, setSortBy] = useState<'CREATED_DATE' | 'COMPANY_NAME' | 'SHORT_DESCRIPTION' | 'TIME_SPEND_ON_TASK' | 'BILLING_COST'>('CREATED_DATE');
    const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC');
    const [logEntries, setLogEntries] = useState<ProjectWorkLogBookEntry[]>([]);
    const [allSelected, setAllSelected] = useState(false);

    useEffect(() => {
        async function fetchInitialLogEntries() {
            const now = new Date();
            let currentYear = now.getFullYear();
            let currentMonth = now.getMonth();

            if (currentMonth === 0) {
                currentYear -= 1;
                currentMonth = 12;
            }

            setYear(currentYear);
            setMonth(currentMonth);

            try {
                const logEntries = await fetchLogEntries(currentYear, currentMonth);
                setLogEntries(logEntries);
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
            }
        }

        fetchInitialLogEntries();
    }, []);

    async function fetchLogEntries(y = year, m = month) {
        setIsLoading(true);
        const auth = backend.withTokenAuthHeader(authentication.token);

        const req: BillingRequest = {year: y, month: m};

        const resp = await backend.internalApi.fetchUnbilled(
            req,
            take,
            (page - 1) * take,
            sortBy,
            sortDirection,
            auth
        );

        return resp.workLogEntries;
    }

    const columns = [
        {
            id: 'select',
            Header: 'select',
            width: 50,
            accessor: (d: any) => <Checkbox
                checked={selected.includes(d)}
                onChange={() => switchSelectionStateOfItem(d)}
            />
        }, {
            id: 'createdDate',
            Header: 'createdDate',
            width: 120,
            accessor: (d: any) => formatDateFromStringNoTime(d.createdDate),
        }, {
            id: 'companyName',
            Header: 'companyName',
            width: 300,
            accessor: (d: any) => d.companyName,
        }, {
            id: 'shortDescription',
            Header: 'shortDescription',
            width: 300,
            accessor: (d: any) => d.shortDescription,
        }, {
            id: 'timeSpentOnTask',
            Header: 'timeSpentOnTask',
            width: 130,
            accessor: (d: any) => d.timeSpentOnTask,
        }, {
            id: 'billingCost',
            Header: 'billingCost',
            width: 100,
            accessor: (d: any) => d.billingCost,
        }];

    async function loadLogEntries() {
        try {
            const logEntries = await fetchLogEntries();
            setLogEntries(logEntries);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    }

    function switchSelectionStateOfItem(entry: any) {
        setIsLoading(true);
        const array = [...selected];
        const index = array.indexOf(entry);

        if (index === -1) {
            setSelected(array.concat(entry));
            console.log("entry added");
        } else {
            array.splice(index, 1);
            setSelected(array);
            console.log("entry removed");
        }
        setIsLoading(false);
    }


    function changePage(newPageIndex: number) {
        setIsLoading(true);
        setPage(newPageIndex);
        loadLogEntries();
    }

    function changePageSize(newPageSize: number) {
        setIsLoading(true);
        setTake(newPageSize);
        loadLogEntries();
    }

    function changeSort(newSortField: 'CREATED_DATE' | 'COMPANY_NAME' | 'SHORT_DESCRIPTION' | 'TIME_SPEND_ON_TASK' | 'BILLING_COST') {
        let newSortDirection = sortDirection;
        if (sortBy === newSortField) {
            newSortDirection = sortDirection === 'ASC' ? 'DESC' : 'ASC';
        }
        setIsLoading(true);
        setSortBy(newSortField);
        setSortDirection(newSortDirection);
        loadLogEntries();
    }

    async function billSelected() {
        setIsLoading(true);
        const auth = backend.withTokenAuthHeader(authentication.token);

        const request: BillWorkLogBookEntriesRequest = {
            logEntryIds: selected.map(s => s._id),
            year,
            month
        };

        const res = await backend.internalApi.billWorkLogBookEntries(request, auth);

        if (res.errors.size > 0) {
            notify.show("ERROR: The following errors appeared: " + JSON.stringify(res.errors, null, 4), 'error', 30000, '#fc0303');
        } else {
            notify.show('successfully billed ' + request.logEntryIds.length + " entries.", 'success', 3000, '#28f751');
        }

        await loadLogEntries();
    }

    function selectOrUnselectAll() {
        if (allSelected) {
            setAllSelected(false);
            setSelected([]);
        } else {
            setAllSelected(true);
            setSelected(logEntries);
        }
    }

    return <>
        <Button icon loading={isLoading} onClick={() => loadLogEntries()}><Icon name='sync'/> Reload </Button>

        <br/>
        <span style={{paddingRight: 20}}>
                <Checkbox
                    checked={allSelected}
                    onChange={() => selectOrUnselectAll()}
                /> - select all
            </span>
        <Button icon loading={isLoading} onClick={() => billSelected()}><Icon name='money bill alternate'/> bill
            selected </Button>

        <ReactTable
            data={logEntries}
            pages={Math.ceil(count / take)}
            columns={columns}
            sorted={[
                {
                    id: 'TIMESTAMP',
                    desc: false
                }
            ]}
            onSortedChange={(newSorted, column) => {
                changeSort(column.sortField);
            }}
            defaultPageSize={take}
            className="-striped -highlight"
            loading={isLoading}
            style={{cursor: "pointer"}}
            onPageChange={(pageIndex) => changePage(pageIndex)}
            onPageSizeChange={(pageSize) => changePageSize(pageSize)}
        />
    </>
}