import * as React from "react";
import {setOpenFiles, StateOfAllTables} from "../slices/tableSlice";
import {useDispatch, useSelector} from "react-redux";
import {Matrix2d, matrix2dToTable} from "../misc/matrix2d";
import "./LoadDataButton.css"
import {ConfigContext} from "../../../../main/context/ConfigContext";
import {CompanyConfiguration, GenericXlsConfigurationOverride} from "../../../../../generated";
import {configStorage} from "../../../../../ConfigStorage";
import {
    changeActiveMode,
    getActiveConfig,
    getActiveMode,
    getSplitterOptions, setSaveToConfig,
    setSplitterOptions,
    SplitterOptions
} from "../slices/ruleSlice";
import InputFormatEnum = GenericXlsConfigurationOverride.InputFormatEnum;
import {Checkbox, Input, Label} from "semantic-ui-react";

// export interface UpdatedSplitterOptions {
//     segmentDocument:boolean
//     shouldSquashBuffer:boolean
//     isElasticPlacementActive:boolean
//     disableHeaderRemoval:boolean
//     useAggressiveLineMerging:boolean
//     insertEmptyLines:boolean
//     splitOnOversizedWhitespaces:boolean
//     currentMode:InputFormatEnum
// }
export interface LoadDataButtonProps {
}

export function LoadDataButton(props: React.PropsWithChildren<LoadDataButtonProps>) {
    const context = React.useContext(ConfigContext)
    const dispatch = useDispatch()

    const [config, setConfig] = React.useState<CompanyConfiguration>(null)
    const [isLoading, setLoading] = React.useState(false);
    const [skipHiddenRows, setSkipHiddenRows] = React.useState(false)
    const [separatorChar, setSeparatorChar] = React.useState(";")
    const [fileEncoding, setFileEncoding] = React.useState("UTF-8")

    const activeConfigName = useSelector(getActiveConfig)
    const activeMode = useSelector(getActiveMode)
    const splitterOptions = useSelector(getSplitterOptions)

    console.log(`Active mode is  ${activeMode}  is  ${activeMode===InputFormatEnum.XLS}`)

    const selectData = async (evt: React.ChangeEvent<HTMLInputElement>) => {
        setLoading(true)

        let uploadedFiles: File[] = Array.from(evt.target?.files)
        let tableResponse: StateOfAllTables = {openFiles: [], activeFile: undefined}
        for (const file of uploadedFiles) {
            let data = new FormData();
            console.log("splitterOptions on END", splitterOptions)
            console.log("Separator char is '"+separatorChar+"'")
            data.append("file", file)
            const url = `/converter/extractMatrix2d/` +
                `?pdfExtractionMode=${activeMode}` +
                `&segmentDocument=${splitterOptions.segmentDocuments}` +
                `&elasticPlacment=${splitterOptions.useElasticCellPlacement}` +
                `&squashBuffer=${splitterOptions.squashBuffer}` +
                `&disableHeaderRemoval=${splitterOptions.disableHeaderRemoval}` +
                `&aggressiveLineMerging=${splitterOptions.aggressiveLineMerging}` +
                `&insertEmptyLine=${splitterOptions.insertEmptyLines}` +
                `&splitOnOversizedWhitespaces=${splitterOptions.splitOnOversizedWhitespaces}`+
                `&skipHiddenRows=${skipHiddenRows}`+
                `&separatorChar=${encodeURIComponent(separatorChar)}`+
                `&fileEncoding=${fileEncoding}`

            //const url = "https://cm.logenios.com/converter/extractMatrix2d/"

            const response = await fetch(url, {method: "POST", body: data})
            const responseData: Matrix2d = await response.json()
            tableResponse.openFiles.push(matrix2dToTable(responseData, file.name ?? ""))
        }
        if (uploadedFiles.length > 0) {
            tableResponse.activeFile = 0
        }

        dispatch(setOpenFiles(tableResponse))
        setLoading(false)
    }

    if (isLoading) {
        return <span>Bitte warten, wird geladen...</span>
    }

    function toggleSingleSplitterOption(
        option: "segmentDocument" |
            "useElasticPlacement" |
            "squashBuffer" |
            "disableHeaderRemoval" |
            "aggressiveLineMerging" |
            "includeEmptyLines" |
            "splitOnOversizedWhitespaces",
        event = null
    ) {
        let newSplitterOptions: SplitterOptions = JSON.parse(JSON.stringify(splitterOptions)) ?? {}
        switch (option) {
            case "segmentDocument":
                newSplitterOptions.segmentDocuments = !splitterOptions.segmentDocuments;
                break;
            case "useElasticPlacement":
                newSplitterOptions.useElasticCellPlacement = !splitterOptions.useElasticCellPlacement;
                break;
            case "squashBuffer":
                newSplitterOptions.squashBuffer = !splitterOptions.squashBuffer;
                break;
            case "disableHeaderRemoval":
                newSplitterOptions.disableHeaderRemoval = !splitterOptions.disableHeaderRemoval;
                break;
            case "aggressiveLineMerging":
                newSplitterOptions.aggressiveLineMerging = !splitterOptions.aggressiveLineMerging;
                break;
            case "includeEmptyLines":
                newSplitterOptions.insertEmptyLines = !splitterOptions.insertEmptyLines;
                break;
            case "splitOnOversizedWhitespaces":newSplitterOptions.splitOnOversizedWhitespaces = !splitterOptions.splitOnOversizedWhitespaces;
                break;
        }
        dispatch(setSplitterOptions(newSplitterOptions))
        // dispatch(setSaveToConfig(true))
        setTimeout(()=>dispatch(setSaveToConfig(true)), 1000)
    }



    return <form className={"loadDataOptions"}>
        <div>
            <Label>Modus</Label>
            <select name="pdfExtractionMode" id="pdfExtractionMode"
                    onChange={(e) => {
                        console.log("%ce.target.value as InputFormatEnum", e.target.value as InputFormatEnum)
                        dispatch(changeActiveMode(e.target.value as InputFormatEnum))
                        setTimeout(()=>dispatch(setSaveToConfig(true)), 1000)
                    }}
                    value={activeMode ?? InputFormatEnum.PDFV2}>
                <option value={InputFormatEnum.PDFV2}>PDF_v2</option>
                <option value={InputFormatEnum.OCRPDFV2}>OCR_PDF_V2</option>
                <option value={InputFormatEnum.GOOGLEOCRPDFV2}>GOOGLE OCR PDFv2</option>
                <option value={InputFormatEnum.XLS}>XLS</option>
                <option value={InputFormatEnum.CSV}>CSV</option>
                <option value={InputFormatEnum.DOCX}>DOCX</option>
                <option value={InputFormatEnum.EDI}>EDI</option>
                <option value={InputFormatEnum.PDF}>PDF</option>
                <option value={InputFormatEnum.XML}>XML</option>
                <option value={InputFormatEnum.HTML}>HTML</option>
                <option value={InputFormatEnum.HTMLV2}>HTML_v2</option>
                <option value={InputFormatEnum.FORTRAS}>FORTRAS</option>
            </select>
        </div>
        <div>
            <Checkbox
                id="segmentDocument"
                name="segmentDocument"
                label='Segment document'
                checked={!!splitterOptions?.segmentDocuments}
                onChange={() => toggleSingleSplitterOption("segmentDocument")}/>
        </div>
        <div>
            <Checkbox
                id="useElasticPlacement"
                name="useElasticPlacement"
                label='Use elastic placement'
                checked={!!splitterOptions?.useElasticCellPlacement}
                onChange={() => toggleSingleSplitterOption("useElasticPlacement")}/>
        </div>
        <div>
            <Checkbox
                id="squashBuffer"
                name="squashBuffer"
                label='Should squash buffer'
                checked={!!splitterOptions?.squashBuffer}
                onChange={() => toggleSingleSplitterOption("squashBuffer")}/>
        </div>
        <div>
            <Checkbox
                id="disableHeaderRemoval"
                name="disableHeaderRemoval"
                label='Disable Header and Footer Removal'
                checked={!!splitterOptions?.disableHeaderRemoval}
                onChange={() => toggleSingleSplitterOption("disableHeaderRemoval")}/>
        </div>
        <div>
            <Checkbox
                id="aggressiveLineMerging"
                name="aggressiveLineMerging"
                label='Enable aggressive line merging'
                checked={!!splitterOptions?.aggressiveLineMerging}
                onChange={() => toggleSingleSplitterOption("aggressiveLineMerging")}/>
        </div>
        <div>
            <Checkbox
                id="includeEmptyLines"
                name="includeEmptyLines"
                label='Insert Empty Lines'
                checked={!!splitterOptions?.insertEmptyLines}
                onChange={() => toggleSingleSplitterOption("includeEmptyLines")}/>
        </div>
        <div>
                    <Checkbox
                        id="splitOnOversizedWhitespaces"
                        name="splitOnOversizedWhitespaces"
                        label='Force split on oversized whitespace'
                        checked={!!splitterOptions?.splitOnOversizedWhitespaces}
                        onChange={() => toggleSingleSplitterOption("splitOnOversizedWhitespaces")}/>
        </div>
        {activeMode===InputFormatEnum.XLS ?
            <div>
                <Checkbox
                    id="skipHiddenRows"
                    name="skipHiddenRows"
                    label='Skip hidden rows [Excel files only]'
                    checked={skipHiddenRows}
                    onChange={() => setSkipHiddenRows(!skipHiddenRows)}/>
            </div> : null
        }
        {activeMode===InputFormatEnum.CSV ?
            <div>
                <Input
                    id="separatorChar"
                    name="separatorChar"
                    label='Field Separator Character [CSV files only, defaults to ;]'
                    value={separatorChar}
                    onChange={(evt) => setSeparatorChar(evt.target.value)}/>
            </div> : null
        }
        {activeMode===InputFormatEnum.CSV ?
            <div>
                <Input
                    id="fileEncoding"
                    name="fileEncoding"
                    label='File Encoding [CSV files only, defaults to UTF-8]'
                    value={fileEncoding}
                    onChange={(evt) => setFileEncoding(evt.target.value)}/>
            </div> : null
        }
        <div>
            <Label>Choose File(s)</Label>
            <Input type={"file"} accept=".xls,.xlsx,.csv,.pdf,.edi,.html,.xml" onChange={selectData} multiple/>
        </div>
    </form>
}