import * as React from "react";
import {Button, Header, Icon, List, ListItem, Modal} from "semantic-ui-react";
import {IfBox} from "../../../../style/if";
import {useContext, useState} from "react";
import {BillingRequest, InvoiceResponse, UploadInvoiceToZohoRequest, ZohoResponse} from "../../../../../generated";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {ConfigContext} from "../../../context/ConfigContext";
import {notify} from 'react-notify-toast';

export interface ZohoModalProps{
    isOpen: boolean
    onClose: () => void
    month?: number
    year?: number
    exportCurrentCompanyOnly: boolean
    exportCreditNotesOnly: boolean
}

export function ZohoModal(props: React.PropsWithChildren<ZohoModalProps>) {

    const context = useContext(ConfigContext);

    const [maxNumberOfInvoices, setMaxNumberOfInvoices] = useState<number | undefined>(undefined);
    const [numberUploadedToZoho, setNumberUploadedToZoho] = useState<number | undefined>(undefined);
    const [isLoadingCreation, setIsLoadingCreation] = useState(false);
    const [isLoadingCreatingInvoices, setIsLoadingCreatingInvoices] = useState(false);
    const [isLoadingUploadCreditNotes, setIsLoadingUploadCreditNotes] = useState(false);

    async function createInvoicesAndUploadToZoho(currentCompanyOnly: boolean, onlyCreditNotes: boolean) {
        let response: InvoiceResponse;
        const auth = backend.withTokenAuthHeader(authentication.token);
        const req: BillingRequest = {month: props.month, year: props.year};

        const failedReqs: ZohoResponse[] = [];

        setIsLoadingCreation(true);
        setNumberUploadedToZoho(0);
        setIsLoadingUploadCreditNotes(true);

        if (currentCompanyOnly) {
            req.companyIdFilter = [context.companyId];
        }

        if (!onlyCreditNotes) {
            setIsLoadingCreatingInvoices(true);
            try {
                response = await backend.internalApi.createInvoicesAndSaveToDB(req, auth);
            } catch (e) {
                console.error(e);
            }
            setIsLoadingCreatingInvoices(false);

            if (response.invoiceResponses) {
                let numberUploadedToZoho = 0;
                const invoices = response.invoiceResponses;
                setMaxNumberOfInvoices(invoices.length);
                setNumberUploadedToZoho(numberUploadedToZoho);

                for (const invoice of invoices) {
                    try {
                        const req: UploadInvoiceToZohoRequest = {invoiceId: invoice.invoiceId};
                        const invoiceResponse = await backend.internalApi.uploadInvoiceToZoho(req, auth);
                        failedReqs.push(...invoiceResponse.zohoResponses.filter(resp => resp.statusCode < 200 || resp.statusCode > 201));
                    } catch (e) {
                        console.error(e);
                        const response: ZohoResponse = {
                            statusCode: -1,
                            resultBody: "Exception occurred, check in Zoho if creditNotes worked"
                        };
                        failedReqs.push(response);
                    } finally {
                        numberUploadedToZoho += 1;
                        setNumberUploadedToZoho(numberUploadedToZoho);
                    }
                }
            }
        }

        if (!currentCompanyOnly) {
            try {
                const creditNoteResponse = await backend.internalApi.uploadCreditNotesToZoho(req, auth);
                failedReqs.push(...creditNoteResponse.zohoResponses.filter(resp => resp.statusCode < 200 || resp.statusCode > 201));
                setIsLoadingCreatingInvoices(false);
            } catch (e) {
                console.error(e);
                const response: ZohoResponse = {
                    statusCode: -1,
                    resultBody: "Exception occurred, check in Zoho if creditNotes worked"
                };
                failedReqs.push(response);
            }
        }

        if (failedReqs.length > 0) {
            console.log("failed Requests: ", failedReqs);
            notify.show(
                <div>
                    <List>
                        {failedReqs.map(req => (
                            <ListItem key={req.statusCode}>{req.resultBody}</ListItem>
                        ))}
                    </List>
                    <button onClick={notify.hide}>close</button>
                </div>, "error", -1, '#fc0303'
            );
            setIsLoadingCreation(false);
        } else {
            setIsLoadingCreation(false);
        }
        setIsLoadingCreation(false);
        setMaxNumberOfInvoices(undefined);
        props.onClose();

    }


    return <Modal
        id='ZohoConfirmModal'
        open={props.isOpen}
        closeOnDimmerClick={false}
        onClose={props.onClose}
        dimmer="blurring"
        size='small'>
        <Header icon='send' content='Export to Zoho'/>
        <Modal.Content>
            {maxNumberOfInvoices == null && !isLoadingCreation ?
                props.exportCurrentCompanyOnly ? "Export to Zoho for current company?" : props.exportCreditNotesOnly ? "Export only credit notes?" : "Export to Zoho for all companies?"
                :
                <div>
                    <div>
                        <IfBox shouldShow={!props.exportCreditNotesOnly}>
                            {isLoadingCreatingInvoices ? <Icon loading name='spinner'/> :
                                <Icon color={"green"} name={'checkmark'}/>}
                            {"Create Invoices"}
                        </IfBox>
                    </div>
                    {!props.exportCreditNotesOnly ? <div>
                        {numberUploadedToZoho !== maxNumberOfInvoices ?
                            <Icon loading name='spinner'/> : <Icon color={"green"} name={'checkmark'}/>}
                        {numberUploadedToZoho} {" von "} {maxNumberOfInvoices ?? "?"} {" Invoices uploaded to Zoho"}
                    </div> : <></>}
                    {props.exportCurrentCompanyOnly ? <></> : <div>
                        {isLoadingUploadCreditNotes ? <Icon loading name='spinner'/> :
                            <Icon color={"green"} name={'checkmark'}/>}
                        {"Uploading Credit Notes"}
                    </div>}
                </div>}
        </Modal.Content>
        <Modal.Actions>
            <Button icon color={'green'} loading={isLoadingCreation}
                    disabled={isLoadingCreation}
                    onClick={() => createInvoicesAndUploadToZoho(props.exportCurrentCompanyOnly, props.exportCreditNotesOnly)}><Icon
                name={'check'}/> Submit</Button>
            <Button icon color={'red'} loading={isLoadingCreation}
                    onClick={props.onClose}><Icon
                name={'close'}/> Close</Button>
        </Modal.Actions>
    </Modal>
}