import * as React from "react";
import {useEffect} from "react";
import {Button, Grid, Header, Icon, Modal} from "semantic-ui-react";
import {
    CompanyWithExcludeInfo,
    ExcludeCompanyFromUnbilledTransportOrdersCalculationRequest
} from "../../../../../../generated";
import {backend} from "../../../../../../xconvert-backend";
import {authentication} from "../../../../../../authentication";


export interface ShowExcludedCompaniesModalProps {
    isOpen: boolean
    onClose: () => void
}

export function ShowExcludedCompaniesModal(props: ShowExcludedCompaniesModalProps) {

    const [excludedCompanies, setExcludedCompanies] = React.useState<CompanyWithExcludeInfo[]>([]);
    const [selectedCompany, setSelectedCompany] = React.useState<CompanyWithExcludeInfo | null>(null);

    useEffect(() => {
        fetchExcludedCompanies().then(() => {
        });
    }, []);

    async function fetchExcludedCompanies() {
        let auth = backend.withTokenAuthHeader(authentication.token);
        let response = await backend.internalApi.getCompaniesExcludedFromUnbilledTransportOrdersCalculation(auth);
        setExcludedCompanies(response.companies);
    }

    const handleClose = () => {
        props.onClose();
    }


    function displayExcludedCompanies() {
        function displayExcludedCompany(company: CompanyWithExcludeInfo) {
            let color = "white";
            if (selectedCompany && selectedCompany.id === company.id) {
                color = "lightgreen";
            }
            return <div
                key={company.id}
                style={{
                    border: "1px grey solid",
                    padding: "15px",
                    margin: "5px",
                    borderRadius: "5px",
                    backgroundColor: color
                }}
                onClick={() => setSelectedCompany(company)}
            >
                <h4>{company.name} ({company.id})</h4>
            </div>
        }

        return excludedCompanies.map((company) => {
            return displayExcludedCompany(company);
        })
    }

    async function removeCompanyFromExcludedCompanies() {
        let auth = backend.withTokenAuthHeader(authentication.token);
        let request = {} as ExcludeCompanyFromUnbilledTransportOrdersCalculationRequest
        request.companyId = selectedCompany?.id!;
        request.action = ExcludeCompanyFromUnbilledTransportOrdersCalculationRequest.ActionEnum.INCLUDE;
        request.reason = "Included by " + authentication.user?.email;

        backend.internalApi.excludeCompanyFromUnbilledTransportOrdersCalculation(request, auth).then(() => {
            console.log("Company included");
            fetchExcludedCompanies().then(() => {

            });
        }).catch((e) => {
            console.error("Error excluding company: ", e);
        });

    }

    function displaySelectedCompany() {
        return <div>
            <h4>{selectedCompany?.name} ({selectedCompany?.id})</h4>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <h5>Reason</h5>
                    </Grid.Column>
                    <Grid.Column>
                        <p>{selectedCompany?.excludeInfo.reason}</p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h5>Excluded by</h5>
                    </Grid.Column>
                    <Grid.Column>
                        <p>{selectedCompany?.excludeInfo.by}</p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h5>Excluded since</h5>
                    </Grid.Column>
                    <Grid.Column>
                        <p>{selectedCompany?.excludeInfo.since}</p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Button icon onClick={() => removeCompanyFromExcludedCompanies()}>
                <Icon name='close'/>
                include (remove from excluded companies)
            </Button>
        </div>
    }

    return <Modal
        open={props.isOpen}
        onClose={handleClose}
        dimmer="inverted"
        size='fullscreen'
        closeIcon
    >
        <Header icon='browser' content='Show excluded companies'/>
        <Modal.Content>
            <Grid padded="horizontally" stackable columns='equal' centered>
                <Grid.Row>
                    <Grid.Column>
                        {displayExcludedCompanies()}
                    </Grid.Column>
                    <Grid.Column>
                        {displaySelectedCompany()}
                    </Grid.Column>
                </Grid.Row>
            </Grid>

        </Modal.Content>
        <Modal.Actions>
            <Button color='green' onClick={handleClose}>close</Button>
        </Modal.Actions>
    </Modal>
}
