import * as React from "react";
import "./DynamicGrid.css";

export interface DisplayFileProps {
    items: JSX.Element[];
}

export function DynamicGrid(props: DisplayFileProps) {
    return <div className="grid-container">
        {props.items.map((item, index) => <div key={index} className="grid-item">{item}</div>)}
    </div>
}