import * as React from "react";
import {Button, Header, Modal} from "semantic-ui-react";
import AceEditor from "react-ace";
import {backend} from "../../../../../../xconvert-backend";
import {authentication} from "../../../../../../authentication";
import {
    CompanyWithUnbilledOrders,
    ExcludeCompanyFromUnbilledTransportOrdersCalculationRequest
} from "../../../../../../generated/index";

export interface ExcludeCompanyModalProps {
    company: CompanyWithUnbilledOrders;
    isOpen: boolean;
    onClose: () => void;
}

export function ExcludeCompanyModal(props: ExcludeCompanyModalProps) {

    const [reason, setReason] = React.useState<string>("");


    const handleClose = () => {
        props.onClose();
    }

    function onSave() {
        console.log("Saving reason: ", reason);
        let auth = backend.withTokenAuthHeader(authentication.token);
        let request = {} as ExcludeCompanyFromUnbilledTransportOrdersCalculationRequest
        request.companyId = props.company._id;
        request.action = ExcludeCompanyFromUnbilledTransportOrdersCalculationRequest.ActionEnum.EXCLUDE;
        request.reason = reason;

        backend.internalApi.excludeCompanyFromUnbilledTransportOrdersCalculation(request, auth).then(() => {
            console.log("Company excluded");
        }).catch((e) => {
            console.error("Error excluding company: ", e);
        });

        handleClose();
    }

    return <Modal
        open={props.isOpen}
        onClose={handleClose}
        dimmer="inverted"
        size='fullscreen'>
        <Header icon='browser' content='Exclude Company from unbilled transportOrders calculation'/>
        <Modal.Content>

            <p>Do you want to exclude company {props.company.name} ({props.company._id}) from the calculation of unbilled transport orders?</p>
            <p>Please give a reason why: </p>

            <AceEditor
                theme="github"
                onChange={(value) => setReason(value)}
                name="reason"
                editorProps={{$blockScrolling: true}}
                style={{width: '100%', height: '200px'}}
            />

            <Button
                onClick={(evt) => onSave()}>Save</Button>
            <Button onClick={(evt) => handleClose()}>Cancel</Button>


        </Modal.Content>
    </Modal>
}