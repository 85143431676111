import * as React from "react";
import {useEffect} from "react";
import {
    CompanyShort,
    CompanyWithUnbilledOrders,
    GetAllUnbilledOrdersForCompanyRequest,
    GetAllUnbilledOrdersForCompanyResponse,
    TransportOrderIdProjection
} from "../../../../../../generated/index";
import {backend} from "../../../../../../xconvert-backend";
import {authentication} from "../../../../../../authentication";
import {Button, Icon, Segment} from "semantic-ui-react";
import {DynamicGrid} from "../../../../../util/dynamicGrid/DynamicGrid";
import {formatDate} from "../../../../../../format";
import {ExcludeCompanyModal} from "./ExcludeCompanyModal";
import {Splitter} from "../../../../../util/table-splitter-ui/src/components/Splitters/Splitter";
import {ShowExcludedCompaniesModal} from "./ShowExcludedCompaniesModal";

export interface UnbilledTransportOrdersTableProps {
    year: number
    setYear: (y: number) => void
    month: number
    setMonth: (m: number) => void
}

export function UnbilledTransportOrdersByCompany(
    {
        year,
        setYear,
        month,
        setMonth
    }: UnbilledTransportOrdersTableProps) {

    const [isLoading, setIsLoading] = React.useState(true)
    const [companiesToCheck, setCompaniesToCheck] = React.useState<CompanyShort[]>([]);
    const [checkedCompanies, setCheckedCompanies] = React.useState<GetAllUnbilledOrdersForCompanyResponse[]>([]);
    const [totalUnbilledOrders, setTotalUnbilledOrders] = React.useState<number>(0);

    const [companyToShow, setCompanyToShow] = React.useState<GetAllUnbilledOrdersForCompanyResponse>(null);


    // Exclude Modal
    const [showExcludeModal, setShowExcludeModal] = React.useState(false);
    const [showExcludedCompaniesModal, setShowExcludedCompaniesModal] = React.useState(false);

    useEffect(() => {
        async function fetchInitialUnbilledTransportOrders() {

            const now = new Date();
            let currentYear = now.getFullYear();
            let currentMonth = now.getMonth();

            if (!year || !month) {
                if (currentMonth === 0) {
                    currentYear -= 1;
                    currentMonth = 12;
                }

                setYear(currentYear);
                setMonth(currentMonth);
            } else {
                currentYear = year;
                currentMonth = month;
            }

            try {
                await fetchCompaniesToCheck(currentYear, currentMonth);
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
            }
        }

        fetchInitialUnbilledTransportOrders();

    }, []);

    async function fetchCompaniesToCheck(y = year, m = month) {
        setIsLoading(true);
        setCheckedCompanies([]);
        setTotalUnbilledOrders(0);
        setCompanyToShow(null);
        setCompaniesToCheck([]);
        let auth = backend.withTokenAuthHeader(authentication.token);
        let response = await backend.internalApi.getAllCompaniesAndBilledOrders(m, y, auth);
        console.log("Companies to check", response)
        setCompaniesToCheck(response.companies)

        for (const c of response.companies) {
            let request = {} as GetAllUnbilledOrdersForCompanyRequest
            request.companyId = c._id;
            request.year = y;
            request.month = m;
            request.billedOrder = response.allBilledOrders;
            getAllUnbilledOrdersForCompany(request) // async
        }
        setIsLoading(false);
    }

    async function getAllUnbilledOrdersForCompany(request: GetAllUnbilledOrdersForCompanyRequest) {
        let auth = backend.withTokenAuthHeader(authentication.token);
        let companyResponse = await backend.internalApi.getAllUnbilledOrdersForCompany(request, auth);
        setCheckedCompanies(old => {
            const newCompanies = [...old, companyResponse];
            newCompanies.sort((a, b) => b.unbilledOrders.length - a.unbilledOrders.length);
            return newCompanies;
        });
        setTotalUnbilledOrders(old => old + companyResponse.unbilledOrders.length)
    }

    function openExcludeModal() {
        setShowExcludeModal(true);
        console.log("open exclude modal");
    }

    function drawCompanyList() {
        function drawCompanyListElement(c: GetAllUnbilledOrdersForCompanyResponse) {

            let color = "white";
            if (companyToShow && companyToShow.company._id === c.company._id) {
                color = "lightgreen";
            }

            return <div
                key={c.company._id}
                style={{
                    border: "1px grey solid",
                    padding: "15px",
                    margin: "5px",
                    borderRadius: "5px",
                    backgroundColor: color
                }}
                onClick={() => setCompanyToShow(c)}
            >
                <h4>{c.company.name} ({c.company._id}) - {c.unbilledOrders.length} Orders</h4>
            </div>
        }

        return <div
            key={"UnbilledOrdersCompanyList"}
            style={{
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
                maxHeight: "1132px"
            }}>
            {checkedCompanies.map(c => drawCompanyListElement(c))}
        </div>
    }

    function drawTransportOrderList() {

        function drawOrderGrid(company: CompanyWithUnbilledOrders) {

            function drawOrderTile(orderProjection: TransportOrderIdProjection) {

                function openOrder(orderProjection: TransportOrderIdProjection) {
                    console.log("open order", orderProjection);
                }

                return <Segment
                    compact
                    onClick={() => openOrder(orderProjection)}
                >
                    <h3>{orderProjection._id}</h3>
                    <p>billingDate: {formatDate(orderProjection.billingDate)}</p>
                </Segment>
            }

            return <DynamicGrid
                items={company.unbilledOrders.map(o => drawOrderTile(o))}
            />
        }

        if (companyToShow) {
            return <div
                style={{
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: "1132px"
                }}>
                <Button
                    style={{height: "50px"}}
                    icon
                    onClick={() => openExcludeModal()}>
                    <Icon name='trash'/>Exclude company
                </Button>
                {companyToShow && drawOrderGrid(companyToShow)}
            </div>
        }
        return <div>Please select a company</div>
    }

    return (
        <div style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            height: "100vh"
        }}>
            <div>
                <Button
                    icon
                    loading={isLoading}
                    onClick={() => fetchCompaniesToCheck()}
                >
                    <Icon name='sync'/> Reload
                </Button>
                <Button
                    icon
                    onClick={() => setShowExcludedCompaniesModal(true)}
                >
                    <Icon name='trash alternate outline'/>show excluded companies
                </Button>
            </div>
            {!companiesToCheck && <h3>Loading companies...</h3>}
            {companiesToCheck && <h3>Checking companies: {checkedCompanies.length}/{companiesToCheck.length}</h3>}
            {checkedCompanies && <div
                key={"UnbilledOrdersSplitterDiv"}
                style={{
                    flex: "1 1 auto",
                    maxHeight: "1132px"
                }}>
                <h3>Total unbilled orders: {totalUnbilledOrders}</h3>
                <Splitter
                    name={"UnbilledTransportOrdersSplitter"}
                    left={drawCompanyList()}
                    right={drawTransportOrderList()}
                />
            </div>
            }
            {showExcludeModal && <ExcludeCompanyModal
                company={companyToShow.company}
                isOpen={showExcludeModal}
                onClose={() => setShowExcludeModal(false)}
            />
            }
            {showExcludedCompaniesModal && <ShowExcludedCompaniesModal
                isOpen={showExcludedCompaniesModal}
                onClose={() => setShowExcludedCompaniesModal(false)}
            />}
        </div>
    );
}