import * as React from "react";
import {useEffect} from "react";
import {Segment} from "semantic-ui-react";
import * as XLSX from 'xlsx/xlsx.mjs';
import Spreadsheet from "@iddan/react-spreadsheet";

export interface CsvViewerProps {
    blob: any;
}

export function CsvViewer({blob}: CsvViewerProps) {

    const [data, setData] = React.useState([]);
    const [workbook, setWorkbook] = React.useState(null);

    useEffect(() => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const binaryStr = e.target?.result;
            const wb = XLSX.read(binaryStr, {type: "binary"});
            setWorkbook(wb);
            openSheet(wb);
        };
        reader.readAsBinaryString(blob);

    }, [blob]);

    function openSheet(wb = workbook) {

        const worksheet = wb.Sheets[wb.SheetNames[0]];
        const sheetData: string[][] = XLSX.utils.sheet_to_json(worksheet, {header: 1});

        const longestRow = sheetData.reduce((acc, row) => row.length > acc ? row.length : acc, 0);
        let fixedSheetData = sheetData.map(row => {
            let newRow = [];
            for (let i = 0; i < longestRow; i++) {
                newRow.push({value: row[i] ?? ""});
            }
            return newRow;
        })
        setData(fixedSheetData);
    }

    return <>
        <Segment style={{
            display: 'flex',
            flexDirection: 'column',
            height: '72vh',
            overflow: 'auto'
        }}>
            <Spreadsheet data={data}/>
        </Segment>
    </>
}