
export const tagList :string[] = [
    "transportOrder.__new__",
    "externalTransportOrderId",
    "externalUserId",
    "targetCompanyName",
    "externalTargetCompanyId",
    "comment",
    "assignedVehicle.numberPlate",
    "assignedVehicle.externalVehicleId",
    "assignedTrailer.numberPlate",
    "assignedTrailer.externalVehicleId",
    "assignedDriver.name",
    "assignedDriver.externalDriverId",
    "assignedDevice.email",
    "assignedDevice.phoneNr",
    "assignedDevice.verboseName",
    "assignedDevice.externalAccesUrl",
    "statusCode",
    "charge.totalAmount",
    "charge.currency",
    "additionalData.<key>",
    "shp.__new__",
    "shp.transportId",
    "shp.loadingLoc.externalStopId",
    "shp.loadingLoc.mergeAndOrderKey",
    "shp.loadingLoc.aiParseAddress",
    "shp.loadingLoc.aiParsePlanTimes",
    "shp.loadingLoc.aiParsePlanTimes.prevWorkingDay",
    "shp.loadingLoc.aiParsePlanTimes.nextWorkingDay",
    "shp.loadingLoc.aiParseOpeningHours",
    "shp.loadingLoc.name1",
    "shp.loadingLoc.name2",
    "shp.loadingLoc.city",
    "shp.loadingLoc.zipCode",
    "shp.loadingLoc.countryCode",
    "shp.loadingLoc.street",
    "shp.loadingLoc.lat",
    "shp.loadingLoc.lon",
    //"shp.loadingLoc.planArrive",
    "shp.loadingLoc.datetime.date",
    "shp.loadingLoc.datetime.timeArrive",
    "shp.loadingLoc.datetime.timeDepart",
    "shp.loadingLoc.freightRefKey",
    "shp.loadingLoc.comment",
    "shp.loadingLoc.additionalData.<key>",
    "shp.loadingLoc.statusCode",
    "shp.unloadingLoc.externalStopId",
    "shp.unloadingLoc.mergeAndOrderKey",
    "shp.unloadingLoc.aiParseAddress",
    "shp.unloadingLoc.aiParsePlanTimes",
    "shp.unloadingLoc.aiParsePlanTimes.prevWorkingDay",
    "shp.unloadingLoc.aiParsePlanTimes.nextWorkingDay",
    "shp.unloadingLoc.aiParseOpeningHours",
    "shp.unloadingLoc.name1",
    "shp.unloadingLoc.name2",
    "shp.unloadingLoc.city",
    "shp.unloadingLoc.zipCode",
    "shp.unloadingLoc.street",
    "shp.unloadingLoc.countryCode",
    "shp.unloadingLoc.lat",
    "shp.unloadingLoc.lon",
    //"shp.unloadingLoc.planArrive",
    "shp.unloadingLoc.datetime.date",
    "shp.unloadingLoc.datetime.timeArrive",
    "shp.unloadingLoc.datetime.timeDepart",
    "shp.unloadingLoc.freightRefKey",
    "shp.unloadingLoc.comment",
    "shp.unloadingLoc.statusCode",
    "shp.unloadingLoc.additionalData.<key>",
    "shp.additionalData.<key>",
    "shp.aiParsePlanTimes",
    "shp.shipmentNumber",
    "shp.comment",
    "shp.freight.__new__",
    "shp.freight.freightId",
    "shp.freight.additionalData.<key>",
    "shp.freight.deliveryNumber",
    "shp.freight.articleNumber",
    "shp.freight.externalFreightId",
    "shp.freight.verboseName",
    "shp.freight.containmentQuantity",
    "shp.freight.containmentName",
    "shp.freight.totalWeight.value",
    "shp.freight.totalLots",
    "shp.freight.totalWeight.unit",
    "shp.freight.totalQuantity.value",
    "shp.freight.length.value",
    "shp.freight.length.unit",
    "shp.freight.width.value",
    "shp.freight.width.unit",
    "shp.freight.height.value",
    "shp.freight.height.unit",
    "shp.freight.totalVolume.value",
    "shp.freight.totalVolume.unit",
    "shp.freight.totalLoadingMeter.value",
    "shp.freight.totalLoadingMeter.unit",
    "shp.freight.comment",
    "shp.freight.freightRefKey",
    "shp.freight.statusCode",
    "shp.freight.payer.name",
    "shp.freight.payer.name2",
    "shp.freight.payer.city",
    "shp.freight.payer.zipCode",
    "shp.freight.payer.street",
    "shp.freight.payer.countryCode",
    "shp.freight.payer.externalPartnerId",
    "shp.freight.shipper.name",
    "shp.freight.shipper.name2",
    "shp.freight.shipper.city",
    "shp.freight.shipper.zipCode",
    "shp.freight.shipper.street",
    "shp.freight.shipper.countryCode",
    "shp.freight.shipper.externalPartnerId",
    "shp.__new__,shp.freight.__new__",
    "transportOrder.__new__,shp.__new__,shp.freight.__new__",
    "pdf.transport.transportId",
    "pdf.transport.transportId+forceAllowMultipleMatchingIndexOperators",
    "pdf.externalTransportOrderId",
    "pdf.transport.fallbackExternalTransportOrderId",
    "shp.freight.packet.__new__",
    "shp.freight.packet.comment",
    "shp.freight.packet.height",
    "shp.freight.packet.length",
    "shp.freight.packet.width",
    "shp.freight.packet.weight",
    "shp.freight.packet.amount",
    "shp.freight.packet.containmentName",
    "shp.freight.packet.containmentNameExt",
    "shp.freight.packet.verboseName",
    "shp.freight.packet.packetId",
    "shp.freight.packet.externalPacketId",
    "x.aiParseShipments",
    "x.aiParseTransportOrder",
    "observer.__new__",
    "observer.verboseName",
    "observer.email",
    "observer.phoneNr",
    "observer.companyId",
];
