import * as React from "react";
import {useEffect, useState} from "react";
import AceEditor from "react-ace";
import {EncodingSelector} from "../../main/pages/docViewer/EncodingSelector";
import 'brace/mode/groovy'
import 'brace/mode/xml'
import 'brace/mode/json'
import 'brace/mode/yaml'

import 'brace/ext/searchbox'
import {ExcelJsViewer} from "./ExcelJsViewer";
import {CsvViewer} from "./CsvViewer";
import {XlsxViewer} from "./XlsxViewer";
import LogeniosErrorBoundary from "../../../ErrorBoundary";


export interface DisplayFileProps {
    fileBuffer: ArrayBuffer;
    fileName: string;
    displayName: boolean;
}

export function FileViewer(props: DisplayFileProps) {

    const [displayedElement, setDisplayedElement] = useState<JSX.Element>(<>Loading...</>)

    useEffect(() => {
        if (props.fileBuffer) {
            drawFilePreview().then(element => {
                setDisplayedElement(element)
            })
        }
    }, [props.fileBuffer])

    function mimeType() {
        switch (fileTypeByFileName(props.fileName)) {
            case 'png':
                return 'image/png'
            case 'jpg':
            case 'jpeg':
                return 'image/jpeg'
            case 'pdf':
                return 'application/pdf'
            case 'xlsx':
            case 'xls':
                return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            default:
                return 'text/plain'
        }
    }

    function getBlob() {
        return new Blob([props.fileBuffer], {type: mimeType()})
    }

    async function drawFilePreview() {
        switch (fileTypeByFileName(props.fileName)) {
            case 'pdf': {
                return <LogeniosErrorBoundary><PdfViewer blob={getBlob()} fileName={props.fileName}/></LogeniosErrorBoundary>
            }
            case 'csv': {
                return <LogeniosErrorBoundary><CsvViewer blob={getBlob()}/></LogeniosErrorBoundary>
            }
            case 'xlsx': {
                return <LogeniosErrorBoundary><XlsxViewer fileBuffer={props.fileBuffer}/></LogeniosErrorBoundary>
                //return <ExcelJsViewer fileBuffer={props.fileBuffer}/>
            }
            case 'png':
            case 'jpg':
            case 'jpeg': {
                return <LogeniosErrorBoundary><ImageViewer blob={getBlob()}/></LogeniosErrorBoundary>
            }
            default: {
                return <LogeniosErrorBoundary><TextFileViewer buffer={props.fileBuffer} fileName={props.fileName}/></LogeniosErrorBoundary>
            }
        }
    }

    return <>
        {props.displayName && <h2>{props.fileName}</h2>}
        {displayedElement}
    </>

}

function fileTypeByFileName(fileName: string) {
    let ending = fileName.toLowerCase().split('.').pop()
    if (ending === 'exp' || ending === 'xsl') {
        return 'xml'
    }
    return ending
}

function PdfViewer({blob, fileName}) {
    return <div>
        <iframe
            id={"attachmentPreviewIFrame"}
            key={0}
            src={URL.createObjectURL(blob)}
            title={fileName}
            height={window.innerHeight - 200 + "px"}
            width={'100%'}
        />
    </div>
}

function TextFileViewer({buffer, fileName}) {
    const [encoding, setEncoding] = useState('utf-8')
    const [fileText, setFileText] = useState('')
    const [fileType, setFileType] = useState('json')

    useEffect(() => {
        setFileType(fileTypeByFileName(fileName))
        setFileText(readFileContent())
    }, [buffer])

    function readFileContent() {
        if (buffer) {
            console.log('Reading file content with encoding', encoding)
            let decoder = new TextDecoder(encoding);
            return beautify(decoder.decode(buffer))
        }
        return ""
    }

    function beautify(uglyText: string) {
        let fileEnding = fileName.toLowerCase().split('.').pop()
        if (fileEnding === 'json') {
            return JSON.stringify(JSON.parse(uglyText), null, 2)
        }
        if (fileEnding === 'xml') {
            console.log("Beautifying XML")
            try {
                const xmlFormatter = require('xml-formatter');
                return xmlFormatter(uglyText)
            } catch (e) {
                console.error('Error beautifying XML', e)
            }
        }
        return uglyText

    }

    return <>
        <AceEditor
            theme="monokai"
            style={{flex: 1}}
            mode={fileType}
            value={fileText}
            placeholder={'Nothing found'}
            width="100%"
            height={window.innerHeight - 200 + "px"}
            readOnly={true}
        />
        <EncodingSelector
            encoding={encoding}
            onChange={(encoding) => {
                setEncoding(encoding)
            }}
        />
    </>
}

function ImageViewer({blob}) {
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        const url = URL.createObjectURL(blob);
        setImageUrl(url);

        // Clean up function to revoke the object URL
        return () => {
            URL.revokeObjectURL(url);
        };
    }, [blob]);

    if (!imageUrl) {
        return <div>Loading...</div>;
    }

    return <img src={imageUrl} alt="From ArrayBuffer"/>;
}