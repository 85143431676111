import * as React from "react";
import {useContext, useEffect} from "react";
import {Button, Checkbox, Dropdown, Grid, Icon, Input, Modal, Popup} from "semantic-ui-react";
import AceEditor from "react-ace";
import {LogView} from "../../logView/LogView";
import ReactTable from "react-table";
import {ConfigContext} from "../../../context/ConfigContext";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {AddOrRemoveCompaniesFromIgnoreListForCustomerReportRequest} from "../../../../../generated";
import DisableOptionsEnum = AddOrRemoveCompaniesFromIgnoreListForCustomerReportRequest.DisableOptionsEnum;
import ServiceTypeEnum = AddOrRemoveCompaniesFromIgnoreListForCustomerReportRequest.ServiceTypeEnum;


const disableOptions = [
    { key: DisableOptionsEnum.UNTILCHANGE, text: 'Until any update in the report', value: DisableOptionsEnum.UNTILCHANGE },
    { key: DisableOptionsEnum.UNTILTOMORROW, text: 'Until Tomorrow', value: DisableOptionsEnum.UNTILTOMORROW },
    { key: DisableOptionsEnum.FORAWEEK, text: 'For a Week', value: DisableOptionsEnum.FORAWEEK },
    { key: DisableOptionsEnum.FORAMONTH, text: 'For a Month', value: DisableOptionsEnum.FORAMONTH },
    { key: DisableOptionsEnum.CUSTOMDATE, text: 'Custom Date', value: DisableOptionsEnum.CUSTOMDATE },
];
export interface CustomerReportSftpStatusProps {
    columns: any[]
    data: any[]
    currentService: ServiceTypeEnum;
    reFetchReportData: () => Promise<void>
}

export function CustomerReportDynamicSection(props: CustomerReportSftpStatusProps) {

    const context = useContext(ConfigContext)
    const auth = backend.withTokenAuthHeader(authentication.token)

    const [json, setJson] = React.useState(null)
    const [index, setIndex] = React.useState(null)
    const [selectedCompanies, setSelectedCompanies] = React.useState<string[]>([])
    const [selectedCompanyObjects, setSelectedCompanyObjects] = React.useState<any[]>([])
    const [modalOpen, setModalOpen] = React.useState(false)
    const [selectedDisableOption, setSelectedDisableOption] = React.useState<DisableOptionsEnum | null>(null);
    const [fromDate, setFromDate] = React.useState<Date | null>(null);
    const [toDate, setToDate] = React.useState<Date | null>(null);
    const [filteredData, setFilteredData] = React.useState<any[]>([]);
    const [showIgnored, setShowIgnored] = React.useState(false);

    useEffect(() => {
        const filtered = props.data?.filter((company: any) => {
            const isIgnored = company.shouldBeIgnored !== undefined
                ? company.shouldBeIgnored : false
            return showIgnored ? isIgnored : !isIgnored;
        });

        setFilteredData(filtered); // Update the filtered data
    }, [props.data, showIgnored, props.currentService]);

    const handleCheckboxChange = (companyId: string, checked: boolean, companyObject: any) => {
        if (checked) {
            setSelectedCompanies([...selectedCompanies, companyId]);
            setSelectedCompanyObjects([...selectedCompanyObjects, companyObject]);
        } else {
            setSelectedCompanies(selectedCompanies.filter(id => id !== companyId));
            setSelectedCompanyObjects(selectedCompanyObjects.filter((company) => company.companyId !== companyId));
        }
    }

    const handleOpenModal = () => setModalOpen(true);

    const handleCloseModal = () => setModalOpen(false);

    const handleSubmit = async () => {
        const payload: AddOrRemoveCompaniesFromIgnoreListForCustomerReportRequest = {
            companyIds: selectedCompanies,        // IDs of the selected companies
            disableOptions: selectedDisableOption, // Convert the string to the correct enum type
            serviceType: props.currentService,    // Current service type (e.g., "Sftp", "ApiGateway")
            enable: false,
            ...(selectedDisableOption === 'CUSTOM_DATE' && {
                fromDate: fromDate,               // Add fromDate if custom date is selected
                toDate: toDate                    // Add toDate if custom date is selected
            }),
            ...(selectedDisableOption === DisableOptionsEnum.UNTILCHANGE && {
                disableUntilObject: selectedCompanyObjects, // Add selected company objects when UNTILCHANGE is selected
            }),
            personWhoIgnored: authentication.user.email ?? ""
        };
        console.log('Payload to be sent to backend to ignore:', payload);
        try {
            console.log('Sending to backend:', payload);
            let auth = backend.withTokenAuthHeader(authentication.token);
            const result = await backend.companyApi.addOrRemoveCompaniesFromIgnoreListForCustomerReport(payload, auth);

            if (result) {
                console.log('Successfully updated companies:', result);
                await props.reFetchReportData();
            } else {
                console.error('Failed to update companies:', result);
            }

        } catch (error) {
            console.error('Error while sending request:', error);
        } finally {
            handleCloseModal();
        }
        handleCloseModal();
    }

    const handleUnIgnore = async () => {
        const payload: AddOrRemoveCompaniesFromIgnoreListForCustomerReportRequest = {
            companyIds: selectedCompanies,        // IDs of the selected companies
            serviceType: props.currentService,    // Current service type (e.g., "Sftp", "ApiGateway")
            enable: true                          // Set to true for un ignoring
        };
        console.log('Payload to be sent for un ignore:', payload);
        try {
            console.log('Sending unignore request to backend:', payload);
            let auth = backend.withTokenAuthHeader(authentication.token);
            const result = await backend.companyApi.addOrRemoveCompaniesFromIgnoreListForCustomerReport(payload, auth);

            if (result) {
                console.log('Successfully unignored companies:', result);
                await props.reFetchReportData();
            } else {
                console.error('Failed to unignore companies:', result);
            }
        } catch (error) {
            console.error('Error while sending unignore request:', error);
        }
    };



    // console.log("CustomerReportDynamicSection - ", props.data)
    if(!props.data) {
        return <div>no data</div>
    }
    return <>
        <div style={{ marginBottom: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>
                <Checkbox
                    toggle
                    label="Show Ignored Companies"
                    checked={showIgnored}
                    onChange={(e, { checked }) => setShowIgnored(checked)}
                    style={{ marginRight: '10px' }}
                />
            </div>
            <Popup
                content={showIgnored ? "Unignore Selected Companies" : "Ignore Selected Companies"}
                trigger={
                    <Button
                        icon
                        onClick={showIgnored ? handleUnIgnore : handleOpenModal}
                        disabled={selectedCompanies.length === 0}
                        style={{ marginLeft: '13px', marginBottom: '8px' }}
                    >
                        <Icon name={showIgnored ? "check" : "ban"} />
                    </Button>
                }
            />
        </div>

        <Grid padded="horizontally" stackable columns='equal' centered>
            <Grid.Column>
                <ReactTable
                    data={filteredData}
                    columns={[
                        {
                            id: 'select',
                            Header: () => <div>Select</div>,
                            accessor: (d: any) => (
                                <Checkbox
                                    checked={selectedCompanies.includes(d.companyId)}
                                    onChange={(e, { checked }) => handleCheckboxChange(d.companyId, checked, d)}
                                />
                            ),
                            width: 100,
                        },
                        ...props.columns
                    ]}
                    defaultPageSize={25}
                    className="-striped -highlight"
                    getTdProps={(state, rowInfo, column, instance) => {
                        return {
                            onClick: () => {
                                setJson(JSON.stringify(rowInfo.row._original, null, 4))
                                setIndex(rowInfo.index)
                            },
                            style: {
                                background: rowInfo?.index === index ? '#00afec' : 'white',
                                color: rowInfo?.index === index ? 'white' : 'black'
                            }
                        };
                    }}
                    style={{ cursor: "pointer" }}
                />
            </Grid.Column>
            <Grid.Column>
                <AceEditor
                    theme="monokai"
                    mode='groovy'
                    value={json}
                    width="100%"
                    height="800px"
                    readOnly={true}
                />
                <LogView query={context.customerReportXReqId} origin={"customerReport"}/>
            </Grid.Column>
        </Grid>

        <Modal open={modalOpen} onClose={handleCloseModal}>
            <Modal.Header>Select Disable Option</Modal.Header>
            <Modal.Content>
                <Dropdown
                    placeholder="Select Option"
                    fluid
                    selection
                    options={disableOptions}
                    onChange={(e, { value }) => setSelectedDisableOption(value as DisableOptionsEnum)}
                />

                {selectedDisableOption === DisableOptionsEnum.CUSTOMDATE && (
                    <>
                        <div style={{ marginTop: '20px' }}>
                            <Input
                                label="From"
                                type="date"
                                value={fromDate ? fromDate.toISOString().split('T')[0] : ""}
                                onChange={(e) => setFromDate(e.target.value ? new Date(e.target.value) : null)}
                            />
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <Input
                                label="To"
                                type="date"
                                value={toDate ? toDate.toISOString().split('T')[0] : ""}
                                onChange={(e) => setToDate(e.target.value ? new Date(e.target.value) : null)}
                            />
                        </div>
                    </>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleCloseModal}>Cancel</Button>
                <Button
                    onClick={handleSubmit}
                    primary
                    disabled={!selectedDisableOption}  // Disable button if no option is selected
                >
                    Submit
                </Button>
            </Modal.Actions>
        </Modal>

    </>

}

export function healthyHighlighter(healthy: boolean) {
    console.log("healthyHighlighter - ", healthy)
    if (healthy) {
        return <Icon name={'heartbeat'} color={'green'}/>
    } else {
        return <Icon name={'ambulance'} color={'red'}/>
    }
}

export function statusHighlighter(status) {
    if (status == "SUCCESS" || status == "OK") {
        return <Icon name={'heartbeat'} color={'green'}/>
    } else {
        if (status == "WARNING") {
            return <Icon name={'ambulance'} color={'yellow'}/>
        } else {
            return <Icon name={'ambulance'} color={'red'}/>
        }
    }
}