import * as React from 'react';
import 'handsontable/dist/handsontable.full.min.css';
import { HyperFormula } from 'hyperformula';

import { registerAllModules } from 'handsontable/registry';
import { HotTable } from '@handsontable/react';
import {useEffect} from "react";

registerAllModules();


export interface HandsonTableProps {
    data: any;
    mergedCells?: any[]
}

export function HandsonTable({ data, mergedCells }: HandsonTableProps) {

    const [preparedData, setPreparedData] = React.useState([]);

    useEffect(() => {
        console.log('HandsonTable mounted with data:', data);
        var prepreparedData = data.map((row: any) => {
            return row.map((cell: any) => {
                if(cell.formula) {
                    return cell.formula;
                }
                return cell.value;
            });
        })
        setPreparedData(prepreparedData);

    }, [data]);

    let exampleData = [
        ['', 'Tesla', 'Volvo', 'Toyota', 'Ford'],
        ['2019', 10, 11, 12, 13],
        ['2020', 20, 11, 14, 13],
        ['2021', 30, 15, 12, 13]
    ];

    //  create an external HyperFormula instance
    const hyperformulaInstance = HyperFormula.buildEmpty({
        // to use an external HyperFormula instance,
        // initialize it with the `'internal-use-in-handsontable'` license key
        licenseKey: 'internal-use-in-handsontable',
    });

    return <HotTable
        data={preparedData}
        rowHeaders={true}
        colHeaders={true}
        height="auto"
        autoWrapRow={true}
        autoWrapCol={true}
        manualColumnResize={true}
        manualRowResize={true}
        formulas={{
            engine: hyperformulaInstance,
            sheetName: 'Sheet1',
        }}
        mergeCells={mergedCells}
        licenseKey="non-commercial-and-evaluation" // for non-commercial use only
    />
}