import * as React from "react";
import {useContext, useState} from "react";
import {ConfigContext} from "../../../context/ConfigContext";
import {Button, ButtonGroup, Input, Tab} from "semantic-ui-react";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {BillingRequest, ProjectWorkLogBookEntry, TransportOrder,} from "../../../../../generated";
import {IfBox} from "../../../../style/if";
import Notifications, {notify} from 'react-notify-toast';
import {DownloadButton} from "../../../../util/react-download-link/DownloadButton";
import {PreviewModal} from "./PreviewModal";
import {FaFileInvoiceDollar} from "react-icons/fa";
import {FaFileExcel} from "react-icons/fa6";
import {ZohoModal} from "./ZohoModal";
import {LogEntriesTable} from "./LogEntriesTable";
import {UnbilledTransportOrdersByCompany} from "./unbilledTransportOrders/UnbilledTransportOrdersByCompany";


export function BillingToolPage() {
    const context = useContext(ConfigContext);
    const [isLoadingCreation, setIsLoadingCreation] = useState(false);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [exportCurrentCompanyOnly, setExportCurrentCompanyOnly] = useState(false);
    const [exportCreditNotesOnly, setExportCreditNotesOnly] = useState(false);
    const [year, setYear] = useState<number | null>(null);
    const [month, setMonth] = useState<number | null>(null);
    const [zipFile, setZipFile] = useState<any>(null);
    const [selectedLogEntries, setSelectedLogEntries] = useState<ProjectWorkLogBookEntry[]>([]);
    const [selectedOrders, setSelectedOrders] = useState<TransportOrder[]>([]);

    async function createBill(currentCompanyOnly: boolean) {
        setIsLoadingCreation(true);
        const auth = backend.withTokenAuthHeader(authentication.token);

        const req: BillingRequest = {month, year};
        if (currentCompanyOnly) {
            req.companyIdFilter = [context.companyId];
        }

        const response = await fetch('/api/internal/createBilling', {
            method: 'POST',
            headers: {
                "Authorization": auth.headers.Authorization.toString(),
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(req)
        });

        if (response.status !== 200) {
            notify.show("ERROR: Backend responded with status:" + response.status + ": " + response.statusText, 'error', 5000, '#fc0303');
            setIsLoadingCreation(false);
        } else {
            const file = new Blob([await response.arrayBuffer()], {type: 'application/zip'});
            setZipFile(file);
            setIsLoadingCreation(false);
        }
    }

    function filterInput() {
        return <span>
            <Input label={'Year'}
                   placeholder='Year' value={year ?? ''}
                   type="number"
                   onChange={evt => {
                       setYear(Number(evt.target.value));
                       setZipFile(null);
                   }}
            />
            <Input label={'Month'}
                   placeholder='Month' value={month ?? ''}
                   type="number"
                   onChange={evt => {
                       setMonth(Number(evt.target.value));
                       setZipFile(null);
                   }}
            />
        </span>
    }

    function buttons() {

        return <div
            style={{
                flex: 1,
                display: "flex",
                flexDirection: "row"
            }}>
            <div
                style={{
                    paddingLeft: 5,
                    paddingRight: 5
                }}>
                <ButtonGroup labeled
                >
                    <Button icon loading={isLoadingCreation} onClick={() => createBill(false)}>
                        <FaFileExcel/> Create Bill for all
                    </Button>
                    <Button icon loading={isLoadingCreation} onClick={() => createBill(true)}>
                        <FaFileExcel/> Create Bill for current company
                    </Button>
                    <IfBox shouldShow={zipFile != null}>
                        <DownloadButton
                            filename={"billing_" + year + "-" + month + ".zip"}
                            exportFile={zipFile}
                        />
                    </IfBox>
                </ButtonGroup>
            </div>
            <div style={{
                paddingLeft: 5,
                paddingRight: 5
            }}>
                <ButtonGroup labeled>
                    <Button icon loading={isLoadingCreation}
                            onClick={() => {
                                setIsUploadModalOpen(true);
                                setExportCurrentCompanyOnly(false);
                                setExportCreditNotesOnly(false);
                            }}><FaFileInvoiceDollar/> Create all and Upload </Button>
                    <Button icon loading={isLoadingCreation}
                            onClick={() => {
                                setIsUploadModalOpen(true);
                                setExportCurrentCompanyOnly(true);
                                setExportCreditNotesOnly(false);
                            }}><FaFileInvoiceDollar/> Create Invoice for current Company and Upload </Button>
                    <Button icon loading={isLoadingCreation}
                            onClick={() => {
                                setIsUploadModalOpen(true);
                                setExportCurrentCompanyOnly(false);
                                setExportCreditNotesOnly(true);
                            }}><FaFileInvoiceDollar/> Create CreditNotes and Upload </Button>
                </ButtonGroup>
            </div>
        </div>
    }

    function ZohoConfirmModal() {
        return <ZohoModal
            isOpen={isUploadModalOpen}
            onClose={() => setIsUploadModalOpen(false)}
            year={year}
            month={month}
            exportCreditNotesOnly={exportCreditNotesOnly}
            exportCurrentCompanyOnly={exportCurrentCompanyOnly}
        />
    }

    function previewModal() {
        return <PreviewModal
            isOpen={zipFile != null}
            onClose={() => setZipFile(null)}
            zipFile={zipFile}
            year={year}
            month={month}
        />
    }

    function drawLogEntryTable() {
        return <LogEntriesTable
            selected={selectedLogEntries}
            setSelected={setSelectedLogEntries}
            year={year}
            setYear={setYear}
            month={month}
            setMonth={setMonth}
        />
    }

    function drawUnbilledTransportOrdersTable() {
        return <UnbilledTransportOrdersByCompany
            year={year}
            setYear={setYear}
            month={month}
            setMonth={setMonth}
        />
    }

    function drawTabView() {
        return <Tab
            panes={[
                {menuItem: 'LogBookEntries', render: () => <Tab.Pane>{drawLogEntryTable()}</Tab.Pane>},
                {menuItem: 'Unbilled TransportOrders', render: () => <Tab.Pane>{drawUnbilledTransportOrdersTable()}</Tab.Pane>},
            ]}
        />
    }

    return (
        <>
            <div
                key={"filterDiv"}
                style={{
                    flex: "0 1 auto",
                    display: "flex",
                    flexDirection: "row"
                }}
            >
                {filterInput()}
                {buttons()}
            </div>
                <br/>
            {drawTabView()}
            {ZohoConfirmModal()}
            {previewModal()}
            <Notifications/>
        </>
    );
}