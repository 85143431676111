import * as React from "react";
import {Header, Modal} from "semantic-ui-react";
import {FileViewer} from "../../../../util/fileViewer/FileViewer";

export interface FtpExplorerFileModalProps {
    isOpen: boolean;
    onClose: () => void;
    fileBuffer: ArrayBuffer;
    fileName: string;
}

export function FtpExplorerFileModal(props: React.PropsWithChildren<FtpExplorerFileModalProps>) {
    const {
        isOpen,
        onClose,
        fileBuffer,
        fileName
    } = props;

    const handleClose = () => {
        onClose();
    }

    return <Modal
        open={isOpen}
        onClose={handleClose}
        closeIcon
        dimmer="blurring"
        size='fullscreen'>
        <Header icon='browser' content={fileName}/>
        <Modal.Content>
            <FileViewer
                fileBuffer={fileBuffer}
                fileName={fileName}
                displayName={false}
            />
        </Modal.Content>
    </Modal>
}