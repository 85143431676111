import * as React from "react";
import {useEffect, useState} from "react";
import {Button, ButtonGroup, Icon, Segment} from "semantic-ui-react";
import {HandsonTable} from "./HandsonTable";

const ExcelJS = require('exceljs');

export interface XlsxViewer2Props {
    fileBuffer: any;
}

export function XlsxViewer({fileBuffer}: XlsxViewer2Props) {

    const [loading, setLoading] = useState(true);
    const [data, setData] = React.useState([]);
    const [sheetIndex, setSheetIndex] = React.useState(0);
    const [workbook, setWorkbook] = React.useState(new ExcelJS.Workbook());
    const [mergedCells, setMergedCells] = React.useState([]);

    useEffect(() => {
        readXlsx();
    }, [fileBuffer]);

    async function readXlsx() {
        setLoading(false);
        let wb = new ExcelJS.Workbook();
        try {
            await wb.xlsx.load(fileBuffer);
        } catch (error) {
            console.error("Error loading XLSX file:", error);
        }
        setWorkbook(wb);
        openSheet(0, wb);
        setLoading(false);
    }

    function openRow(row, highestColumnIndex) {
        const newRow = [];


        for (let i = 1; i <= highestColumnIndex + 1; i++) {
            let cell = row[i];
            if (cell) {
                if (cell?.formula) {
                    newRow.push({formula: "=" + cell.formula ?? ""});
                } else {
                    newRow.push({value: cell ?? ""});
                }
            } else {
                newRow.push({value: ""});
            }

        }
        return newRow;
    }

    function getHighestIndex(map) {
        let highestIndex = 0
        Object.keys(map).sort().forEach(cellIndex => {
            highestIndex = Number.parseInt(cellIndex)
        })
        return highestIndex
    }

    function openSheet(index = sheetIndex, wb = workbook) {
        try{
            const worksheet = wb.worksheets[index];
            if (worksheet) {
                const sheetData = worksheet.getSheetValues();

                const fixedSheetData = []

                let highestColumnIndex = 0;
                sheetData.forEach(row => {
                    let highestIndex = getHighestIndex(row);
                    if (highestIndex > highestColumnIndex) {
                        highestColumnIndex = highestIndex;
                    }
                })
                for (let i = 1; i <= sheetData.length + 1; i++) {
                    let row = sheetData[i];
                    if (row) {
                        fixedSheetData.push(openRow(row, highestColumnIndex));
                    } else {
                        let emptyRow = [];

                        for (let j = 1; j <= highestColumnIndex + 1; j++) {
                            emptyRow.push({value: ""});
                        }
                        fixedSheetData.push(emptyRow);
                    }
                }

                setData(fixedSheetData);
                calculateMergedCells(worksheet);
            }
        }catch(e) {
            console.log("Failed to open file!", e)
        }
    }

    function calculateMergedCells(worksheet) {
        const merges = worksheet._merges;
        const mergedCells = Object.values(merges).map((merge: any) => {
            let model = merge.model;
            return {
                row: model.top-1,
                col: model.left-1,
                rowspan: (model.bottom - model.top)+1,
                colspan: (model.right - model.left)+1
            }
        });

        setMergedCells(mergedCells);
    }

    function worksheetSelector() {
        let worksheetButtons = []
        workbook.worksheets.forEach((sheet, index) => {
            console.log(sheet, index);

            worksheetButtons.push(
                <Button
                    key={index}
                    primary={index === sheetIndex}
                    onClick={() => {
                        setSheetIndex(index)
                        openSheet(index)
                    }}
                    value={workbook.worksheets[index]}
                >
                    {sheet.name}
                </Button>
            )

        })
        return <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            overflow: 'auto',
            marginTop: '1em'
        }}><ButtonGroup>{worksheetButtons}</ButtonGroup>
            {disclaimer}
        </div>

    }

    const disclaimer = <>
        <Icon name={"info circle"} color={"blue"} size={"large"}/>
        <>
            Note: This is a preview of the data in the file.
            It doesn't include formatting and similar styling features.
            Please download the file and open it in Excel for full functionality.
        </>
    </>

    if (loading) {
        return <div>Loading...</div>;
    }

    return <>
        <Segment style={{
            display: 'flex',
            flexDirection: 'column',
            height: '72vh',
            overflow: 'auto'
        }}>
            <HandsonTable
                data={data}
                mergedCells={mergedCells}
            />
        </Segment>
        {worksheetSelector()}
    </>
}